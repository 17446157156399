import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useApi, configApiRef } from '@backstage/core-plugin-api';

const CostComponent: React.FC = () => {
  const entity = useEntity().entity;
  const namespace = entity.metadata.annotations?.['grafana/namespace'];
  const config = useApi(configApiRef);
  const url = config.getString('opencost.grafanaDashboard')
  const grafanaOpenCostDashboardUrl = `${url}${namespace}`;

  return (
    namespace ? <iframe  title={grafanaOpenCostDashboardUrl} src={grafanaOpenCostDashboardUrl} width="100%" height="100%" referrerPolicy="strict-origin-when-cross-origin" frameBorder="0" /> : null
  );
};

export default CostComponent;
