import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';

const GorillaComponent: React.FC = () => {
  const entity = useEntity().entity;
  const targetUrl = entity.metadata.annotations?.['grafana/probe-url'];
  const grafanaHealthCheckUrl = `https://grafana.ovh.alebox.xyz/d/sLghsigGGsa/backstage-application-health-check?orgId=1&refresh=10s&var-interval=10s&var-target=${targetUrl}&from=now-6h&to=now&kiosk`

  return (
    targetUrl ? <iframe style={{paddingBottom: '10px'}} title={grafanaHealthCheckUrl} src={grafanaHealthCheckUrl} width="100%" height="100%" referrerPolicy="strict-origin-when-cross-origin" frameBorder="0" /> : null
  );
};

export default GorillaComponent;
