import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  ApiRef,
  BackstageIdentityApi,
  OAuthApi,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  oauthRequestApiRef,
} from '@backstage/core-plugin-api';
import { OAuth2 } from '@backstage/core-app-api';

export const oauth2ApiRef: ApiRef<
  OAuthApi &
  OpenIdConnectApi &
  ProfileInfoApi &
  BackstageIdentityApi &
  SessionApi
> = createApiRef({
  id: 'auth.oidc',
});

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: oauth2ApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      OAuth2.create({
        configApi: configApi,
        defaultScopes: ['openid', 'profile', 'email'],
        discoveryApi: discoveryApi,
        environment: configApi.getOptionalString('auth.environment'),
        oauthRequestApi: oauthRequestApi,
        provider: {
          id: 'oidc',
          title: 'OIDC auth provider',
          icon: () => null,
        },
        // popupOptions: {

        //   // optional, used to customize login in popup size
        //   size: {
        //     fullscreen: true,
        //   },
        //   /**
        //    * or specify popup width and height
        //    * size: {
        //       width: 1000,
        //       height: 1000,
        //     }
        //    */
        // },
      }),
  }),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  // createApiFactory({
  //   api: oauth2ApiRef,
  //   deps: {
  //     discoveryApi: discoveryApiRef,
  //     oauthRequestApi: oauthRequestApiRef,
  //     configApi: configApiRef,
  //   },
  //   factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
  //     OAuth2.create({
  //       discoveryApi,
  //       oauthRequestApi,
  //       defaultScopes: ['openid', 'email', 'profile', 'offline_access'],
  //       environment: configApi.getOptionalString('auth.environment'),
  //     }),
  // }),
  ScmAuth.createDefaultApiFactory(),
];
