import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';

const SecurityComponent: React.FC = () => {
  const entity = useEntity().entity;
  const namespace = entity.metadata.annotations?.['grafana/namespace'];
  const grafanaTrivyDashboardUrl = `https://grafana.ovh.alebox.xyz/d/trivy_vulnerabilities/trivy-operator-vulnerabilities?orgId=1&refresh=30s&var-datasource=Mimir&var-namespace=${namespace}&var-resolution=30s&kiosk`

  return (
    namespace ? <iframe  title={grafanaTrivyDashboardUrl} src={grafanaTrivyDashboardUrl} width="100%" height="100%" referrerPolicy="strict-origin-when-cross-origin" frameBorder="0" /> : null
  );
};

export default SecurityComponent;
